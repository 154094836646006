.theme-PahlmansGy {
    // Colors
    --body-bg: #{palette(black, 2)};
    --primary-color: #{palette(green)};
    --secondary-color: #{palette(purple)};
    --text-color: #{palette(black, 72)};
    --heading-color: #{black};
    --billboard-color: #{palette(green, 56)};
    --billboard-button-hover-color: #{palette(green, 44)};
    --footer-color: #{palette(green)};
    --tag-color: #{palette(black, 46)};
    --menu-hover: #{palette(black, 3)};
    --menu-active: #{palette(purple)};
    --menu-icon-hover: #{palette(black, 5)};
    --pagination-hover-color: #{palette(green, 76)};
    --calendar-hover-color: #{palette(green, 76)};
    --cookie-accordion: #{palette(green, 76)};
    --search-item-event-color: #{palette(green, 76)};
    --link-color: #{palette(purple)};
    --link-hover-color: #{darken(palette(purple), 10%)};
    --error-color: #{palette(red)};
    --invalid-color: #{palette(red, system)};
    --valid-color: #{palette(green, system)};
    --button-ghost-border-before-color: #{palette(black, 84)};
    --button-ghost-border-after-color: #{palette(black, 46)};

    // Images
    --bg-image: url('../#{$basepath}img/graphic-man-pahlmans-gy.webp');
    --bg-arrow: url('../#{$basepath}img/bg-icons/pahlmans/arrow--green.svg');
    --dropdown: url('../#{$basepath}img/bg-icons/pahlmans/dropdown--purple.svg');
    --download: url('../#{$basepath}img/bg-icons/pahlmans/download--purple.svg');
    --bg-check: url('../#{$basepath}img/bg-icons/pahlmans/check--green.svg');
    --bg-alert: url('../#{$basepath}img/bg-icons/pahlmans/alert--purple.svg');
    --cookie: url('../#{$basepath}img/bg-icons/pahlmans/cookie--green.svg');
}
