// Any theme variables should be added as css-variables
* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(black, 0);
}

html {
    height: 100%;
    font-size: 62.5%;
}

body {
    font-family: var(--font-base);
    color: var(--text-color);
    font-size: 1.6rem;
    line-height: 1.5;
    background: var(--body-bg);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    transition: transform .4s cubic-bezier(.20,1,.22,1);

    &.no-scroll {
        overflow: hidden;
    }

    &.no-scroll--xs {
        @include media-max(xs) {
            overflow: hidden;
        }
    }

    // Medborgarskolan doesn't have a menu page that scrolls to top
    &.MenuPageMobileActive--ScrollToTop,
    &.MenuMobileActive {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: hidden;

        @include media(md) {
            position: relative;
            width: auto;
            height: auto;
            overflow: auto;
            transform: translateY(0) !important;
        }
    }

    * {
        outline-color: var(--focus-color);
        outline-width: 3px;
    }
}

strong {
    font-weight: $bold;
}

a {
    color: var(--text-color);
    text-decoration: none;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    color: var(--heading-color);
    line-height: 1.2;
    font-family: var(--font-heading);
    font-weight: var(--weight-heading);
}

h1, .h1 {
    font-size: $font-size-h1-d;

    @include media(sm) {
        font-size: $font-size-h1-md;
    }
}

h2, .h2 {
    font-size: $font-size-h2-d;

    @include media(sm) {
        font-size: $font-size-h2-sm;
    }
}

h3, .h3 {
    font-size: $font-size-h3-d;

    @include media(sm) {
        font-size: $font-size-h3-sm;
    }
}

// TODO: Check if outline is added to all styles per element
// *:focus {
//     outline: none;
// }

.svg-clip-path {
    display: block;
    position: absolute;
    height: 0;
}

// Stolen with pride from bootstrap
.sr-only {
    @extend %sr-only;
}
