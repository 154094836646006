.theme-Pahlmans {
    // Colors
    --body-bg: #{palette(black, 2)};
    --primary-color: #{palette(blue)};
    --secondary-color: #{palette(red)};
    --text-color: #{palette(black, 72)};
    --heading-color: #{black};
    --billboard-color: #{palette(blue, 34)};
    --billboard-button-hover-color: #{palette(blue, 47)};
    --footer-color: #{palette(blue)};
    --tag-color: #{palette(blue, 47)};
    --menu-hover: #{palette(blue, 5)};
    --menu-active: #{palette(blue)};
    --menu-icon-hover: #{palette(blue, 15)};
    --pagination-hover-color: #{palette(blue, 5)};
    --calendar-hover-color: #{palette(blue, 5)};
    --cookie-accordion: #{palette(blue, 5)};
    --search-item-event-color: #{palette(blue, 5)};
    --link-color: #{palette(blue)};
    --link-hover-color: #{darken(palette(blue), 10%)};
    --error-color: #{palette(red)};
    --invalid-color: #{palette(red, system)};
    --valid-color: #{palette(green, system)};
    --button-ghost-border-before-color: #{palette(black, 84)};
    --button-ghost-border-after-color: #{palette(black, 46)};

    // Images
    --bg-image: url('../#{$basepath}img/graphic-man-pahlmans.webp');
    --bg-arrow: url('../#{$basepath}img/bg-icons/pahlmans/arrow--blue.svg');
    --dropdown: url('../#{$basepath}img/bg-icons/pahlmans/dropdown--blue.svg');
    --download: url('../#{$basepath}img/bg-icons/pahlmans/download--blue.svg');
    --bg-check: url('../#{$basepath}img/bg-icons/pahlmans/check--blue.svg');
    --bg-alert: url('../#{$basepath}img/bg-icons/pahlmans/alert--red.svg');
    --cookie: url('../#{$basepath}img/bg-icons/pahlmans/cookie--blue.svg');
}
