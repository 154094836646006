@import '../includes';

.theme-Pahlmans {
    --text-color: #{$var-text-color};
    --heading-color: #{$var-heading-color};
    --focus-color: #{$var-focus-color};
    --body-bg: #{$var-body-bg};
    --weight-heading: #{$var-weight-heading};
}

// TODO: Maybe add default strong as css variable
// strong {
//     font-weight: $semibold;
// }

// TODO: Make sure pahlmans theme is medium
// h1, .h1,
// h2, .h2,
// h3, .h3,
// h4, .h4,
// h5, .h5 {
//     font-weight: $medium;
// }

// TODO: Make sure heading sizes are used as css variables instead
// h1, .h1 {
//     font-size: 2.8rem;

//     @include media(md) {
//         font-size: 3.2rem;
//     }
// }

// h2, .h2 {
//     font-size: 2.4rem;

//     @include media(sm) {
//         font-size: 2.8rem;
//     }
// }

// h3, .h3 {
//     font-size: 2.2rem;

//     @include media(sm) {
//         font-size: 2.6rem;
//     }
// }
