// @import '~react-select/scss/default';

// Custom styling for react-select

.Select {
    width: calc(100% - 73px);
    text-align: left;

    @include media(md) {
        width: calc(100% / 3);
    }
}

.Select__control {
    height: 56px;
    border: 0;
    border-radius: 0;
    font-weight: normal;
    text-align: left;
}

.Select__value-container {
    height: 56px;
    padding-left: 16px;
    font-size: 1.6rem;
}

.Select__single-value {
    position: relative;
}

.Select__input {
    font-weight: normal;
    line-height: 56px;
}

.Select__placeholder {
    white-space: nowrap;
    color: #1F1F1F;

    .Select__control--is-focused & {
        color: #AAAAAA;
    }
}

.Select__option,
.Select__noresults {
    height: 50px;
    line-height: 35px;
    padding-left: 16px;
    font-weight: normal;
}

// Used as group label
.Select__option--is-disabled {
    height: 50px;
    padding-bottom: 5px !important;
    color: black;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
    background: #f6f6f6;
}

.Select__option--is-disabled ~ .Select__option:not(.Select__option--is-disabled) {
    padding-left: 32px;
}

.Select__indicator-separator {
    opacity: 0;
}

.Select__clear-indicator {
    padding-right: 0;

    @include media(sm) {
        padding-right: 10px;
    }
}

.Select__dropdown-indicator {

    @include media(sm) {
        padding-right: 16px;
    }

    svg {
        display: none;
    }

    &::before {
        content: '';
        display: inline-block;
        width: 17px;
        height: 12px;
        border: none;
        background: url('../#{$basepath}img/bg-icons/base/dropdown.svg') no-repeat center center;
        background-size: contain;
        opacity: .86;

        .Select__control--menu-is-open & {
            top: 0;
            transform: rotate(180deg);
        }
    }
}
