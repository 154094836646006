@keyframes FilterEventAccordianNoJs {
    0% {
        max-height: 0;
    }
    100% {
        max-height: 1000px;
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes FadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes moveLoadingGradient {
    from {
        left: -50px;
    }
    to {
        left: calc(100% + 50px);
    }
}

@keyframes stickerRoll {
    from {
        background-position: 0px 0px;
    }
    to {
        background-position: 1000px 0;
    }
}

@keyframes heroCampaignContentFadeIn {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    10% {
        opacity: 0;
    }
    80% {
        transform: translateX(0px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}